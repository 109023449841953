import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { useMutationHTTPRequest } from 'api/hooks/useHTTPRequest';
import { getParticipantsSprintsQueryKey } from 'api/utils';
import { logError } from 'lib/sentry/logError';

import { QUERY_KEYS, SPRINT_TYPES } from 'features/sprint/config';
import { useUserId } from 'features/user/hooks/useUserId';

import { useSprintAnalytics } from '../useSprintAnalytics';

type TSprintStartMutation = {
  email: string;
  sprintType?: typeof SPRINT_TYPES[keyof typeof SPRINT_TYPES];
};

const useSprintStartMutation = ({
  email,
  sprintType,
}: TSprintStartMutation) => {
  const userId = useUserId();
  const queryClient = useQueryClient();

  const { request } = useMutationHTTPRequest();
  const { sendSprintStartedEvent } = useSprintAnalytics();

  const queryKey =
    sprintType === SPRINT_TYPES.COACHED
      ? getParticipantsSprintsQueryKey(email)
      : [queryKeys.mySprints];

  return useMutation(
    async ({ sprintId }: { sprintId: string }) =>
      await request({
        url: `/api/sprints/${sprintId}/start`,
      }),
    {
      onSuccess: async (_, { sprintId }: { sprintId: string }) => {
        await queryClient.invalidateQueries(queryKey);
        await queryClient.invalidateQueries(QUERY_KEYS.SPRINT(sprintId));
        await queryClient.invalidateQueries([queryKeys.sprints, userId]);
        await queryClient.invalidateQueries([queryKeys.draftSprints, userId]);

        sendSprintStartedEvent();
      },
      onError: (error) => {
        logError(error);
      },
    }
  );
};

export default useSprintStartMutation;
