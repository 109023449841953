import { BYTES_IN_MEGABYTE } from 'shared_DEPRECATED/components/File/config';

import { DIMENSIONS } from 'features/challenge/config';
import { COACHING_MODE } from 'features/participants/config';
import {
  DimensionChallengeNotesType,
  SessionPhaseType,
} from 'features/sessions/config/types';

import {
  SessionFormRecapImmediateNextStepsTemplate,
  SessionsFormRecapStandardTemplate,
  SessionsFormRecapsTodayWeCoveredTemplate,
} from '../components/Form/Recap/Templates';

export const SESSION_ATTENDANCE_STATUS = {
  ATTENDED: 'attended',
  RESCHEDULED: 'rescheduled',
  CANCELED: 'canceled',
  NO_SHOW: 'no_show',
};

export const ATTENDANCE_COLOR_MAP = {
  [SESSION_ATTENDANCE_STATUS.ATTENDED]: {
    backgroundColor: '#7AD7AC',
    borderColor: '#45CB83',
  },
  [SESSION_ATTENDANCE_STATUS.RESCHEDULED]: {
    backgroundColor: '#B5A3FB',
    borderColor: '#7A5AF8',
  },
  [SESSION_ATTENDANCE_STATUS.CANCELED]: {
    backgroundColor: '#F87C79',
    borderColor: '#F5413D',
  },
  [SESSION_ATTENDANCE_STATUS.NO_SHOW]: {
    backgroundColor: '#F87C79',
    borderColor: '#F5413D',
  },
};

export const LATE_STATUS_TIME_BY_COACHING_MODE = {
  [COACHING_MODE.BIWEEKLY_30_MIN]: 5,
  [COACHING_MODE.BIWEEKLY_60_MIN]: 15,
  [COACHING_MODE.WEEKLY_30_MIN]: 5,
  [COACHING_MODE.WEEKLY_60_MIN]: 15,
  [COACHING_MODE.BOOKENDS_60_MIN]: 15,
  [COACHING_MODE.BOOKENDS_30_MIN]: 5,
  [COACHING_MODE.OPT_IN_60_MIN]: 15,
  [COACHING_MODE.OPT_IN_30_MIN]: 5,
};

export const SESSION_ATTENDANCE_LABEL = {
  [SESSION_ATTENDANCE_STATUS.ATTENDED]: 'Attended',
  [SESSION_ATTENDANCE_STATUS.RESCHEDULED]: 'Reschedule',
  [SESSION_ATTENDANCE_STATUS.CANCELED]: 'Cancellation',
  [SESSION_ATTENDANCE_STATUS.NO_SHOW]: 'No-show',
};

export const COLOR_BY_SESSION_ATTENDANCE_STATUS = {
  [SESSION_ATTENDANCE_STATUS.ATTENDED]: 'green',
  [SESSION_ATTENDANCE_STATUS.RESCHEDULED]: 'orange',
  [SESSION_ATTENDANCE_STATUS.CANCELED]: 'red',
  [SESSION_ATTENDANCE_STATUS.NO_SHOW]: 'red',
};

export const TABLE_CELL_DEFAULT_VALUE = '-';

export const DEFAULT_SESSION_DURATION = 45;

export const COACHING_MODE_SESSION_DURATION = {
  [COACHING_MODE.BIWEEKLY_30_MIN]: 30,
  [COACHING_MODE.BIWEEKLY_60_MIN]: 60,
  [COACHING_MODE.WEEKLY_30_MIN]: 30,
  [COACHING_MODE.WEEKLY_60_MIN]: 60,
  [COACHING_MODE.BOOKENDS_60_MIN]: 60,
  [COACHING_MODE.BOOKENDS_30_MIN]: 30,
  [COACHING_MODE.OPT_IN_60_MIN]: 60,
  [COACHING_MODE.OPT_IN_30_MIN]: 30,
};

export const SESSIONS_TABS_TYPES = {
  CHALLENGES_AND_DIMENSION: 'CHALLENGES_AND_DIMENSION',
  COACHING_TOOLS: 'COACHING_TOOLS',
  RECAP: 'RECAP',
};

export const SESSIONS_TABS_CONFIG = [
  {
    label: 'Dimensions & Challenges',
    type: SESSIONS_TABS_TYPES.CHALLENGES_AND_DIMENSION,
    dataTestid: 'dimensions-challenges-tab',
  },
  {
    label: 'Coaching tools',
    type: SESSIONS_TABS_TYPES.COACHING_TOOLS,
    dataTestid: 'coaching-tool-tab',
  },
  {
    label: 'Session Recap',
    type: SESSIONS_TABS_TYPES.RECAP,
    dataTestid: 'session-recap-tab',
  },
];

export const NO_ACTIVE_SPRINT = 'No active sprint';

export const initialDimensionNotes = Object.values(
  DIMENSIONS
).reduce<DimensionChallengeNotesType>(
  (notes, dimension) => {
    notes[dimension] = { dimension, text: '' };

    return notes;
  },
  {
    Other: { dimension: 'Other', text: '' },
  }
);

export const SESSION_RECAP_STATUS = {
  SUBMITTED: 'submitted',
  SENT: 'sent',
  NOT_SENT: 'not_sent',
};

//Gmail - 25MB, Outlook - 20MB, AppleMail - 20MB = safe value 20MB
const ATTACHMENTS_SIZE_LIMIT_IN_MB = 20;
export const ATTACHMENTS_SIZE_LIMIT_IN_BYTES =
  BYTES_IN_MEGABYTE * ATTACHMENTS_SIZE_LIMIT_IN_MB;

export const RECAP_SUBJECT_DEFAULT_TEXT = 'hOS Coaching Session Recap';
export const DEFAULT_RECAP_DATA = {
  subject: RECAP_SUBJECT_DEFAULT_TEXT,
  content: '',
  attachments: [],
};

const editMode = { editMode: true };

export const defaultCoachingTool = {
  ...editMode,
  tool: {
    label: '',
    value: '',
  },
  link: null,
  unlistedToolName: '',
};

export const defaultSessionCoachingTool = {
  ...defaultCoachingTool,
  notes: '',
  toolEffectivenessRate: '1',
  attachments: [],
};

export const COACHING_EXTERNAL_TOOL = 'external_tool';

export const SESSION_PHASE = {
  SPRINT: 'sprint',
  BRIDGE_MODE: 'bridge_mode',
  ONBOARDING: 'onboarding',
};

export const SESSION_PHASE_LABEL: Record<SessionPhaseType, string> = {
  [SESSION_PHASE.BRIDGE_MODE]: 'Bridge Mode',
  [SESSION_PHASE.ONBOARDING]: 'Onboarding',
  [SESSION_PHASE.SPRINT]: 'Sprint',
};

export const SESSION_RECAP_TEMPLATES = {
  STANDARD: 'standard',
  TODAY_WE_COVERED: 'today_we_covered',
  NEXT_STEPS: 'next_steps',
} as const;

export const SESSION_RECAP_TEMPLATE_LABEL_MAP = {
  [SESSION_RECAP_TEMPLATES.STANDARD]: 'Standard',
  [SESSION_RECAP_TEMPLATES.TODAY_WE_COVERED]: 'Today we covered',
  [SESSION_RECAP_TEMPLATES.NEXT_STEPS]: 'Immediate next steps',
};

export const SESSION_RECAP_TEMPLATE_MAP = {
  [SESSION_RECAP_TEMPLATES.STANDARD]: SessionsFormRecapStandardTemplate,
  [SESSION_RECAP_TEMPLATES.TODAY_WE_COVERED]:
    SessionsFormRecapsTodayWeCoveredTemplate,
  [SESSION_RECAP_TEMPLATES.NEXT_STEPS]:
    SessionFormRecapImmediateNextStepsTemplate,
};
