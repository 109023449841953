import { VStack } from '@chakra-ui/react';

import { Typography, RadioButtonGroup } from 'shared';
import FieldLabel from 'shared_DEPRECATED/components/Form/FieldLabel';

import { useDifficultyRatingFieldConfig } from 'features/challenge/hooks/useDifficultyRatingFieldConfig';

export const ChallengeDifficultyField = () => {
  const { value, onChange, options } = useDifficultyRatingFieldConfig();

  return (
    <VStack alignItems="flex-start" gap={3}>
      <FieldLabel>Challenge difficulty</FieldLabel>
      <Typography color="gray" type="small">
        This impacts how much Momentum is earned for completion
      </Typography>
      <RadioButtonGroup
        value={value}
        onChange={onChange}
        options={options}
        buttonProps={{ size: 'xs' }}
      />
    </VStack>
  );
};
