import { useMutation, useQueryClient } from '@tanstack/react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';

import {
  PlanningDraftSprint,
  usePlanningDraftSprintsQueryActions,
} from 'features/planning';
import { SPRINT_SOURCE_TYPES, SPRINT_STATES } from 'features/sprint/config';
import { useUserId } from 'features/user/hooks/useUserId';

import { getUuId } from 'shared/utils/uuid';

export const useAddPlanningDraftSprintMutation = () => {
  const queryClient = useQueryClient();
  const userId = useUserId();

  const { request } = useMutationHTTPRequest();
  const {
    cancelDraftSprintsQueries,
    getDraftSprints,
    updateDraftSprints,
    invalidateDraftSprintsQuery,
  } = usePlanningDraftSprintsQueryActions();

  return useMutation(
    () =>
      request({
        url: `/api/draft-sprints/create-empty`,
        body: { forUserId: userId },
      }),
    {
      onMutate: async () => {
        const temporarySprintId = getUuId();

        const temporaryDraftSprint = new PlanningDraftSprint({
          sprintType: SPRINT_SOURCE_TYPES.TRADITIONAL,
          challenges: [],
          sprintId: temporarySprintId,
          state: SPRINT_STATES.DRAFT,
          updatedAt: '',
          coachingTools: [],
          title: '',
        });
        await cancelDraftSprintsQueries();

        const previousDraftSprintsValue = getDraftSprints();

        if (previousDraftSprintsValue) {
          updateDraftSprints([
            ...previousDraftSprintsValue.items,
            temporaryDraftSprint,
          ]);
        }

        return { previousDraftSprintsValue, temporarySprintId };
      },
      onError: (err, _, context) => {
        queryClient.setQueryData(
          [queryKeys.draftSprints, userId],
          context?.previousDraftSprintsValue
        );

        logError(err);
      },
      onSuccess: ({ sprint: newDraftSprint }, _, context) => {
        const oldDraftSprintsValue = getDraftSprints();

        updateDraftSprints(
          oldDraftSprintsValue!.items.map((draftSprint) =>
            draftSprint.sprintId === context?.temporarySprintId
              ? newDraftSprint
              : draftSprint
          )
        );

        invalidateDraftSprintsQuery();
      },
    }
  );
};
