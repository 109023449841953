import { NonRepeatedChallenge } from 'features/challenge/NonRepeatedChallenge';
import { RepeatedChallenge } from 'features/challenge/RepeatedChallenge';
import {
  CHALLENGE_FIELDS,
  MAX_SPRINT_CHALLENGES_COUNT,
} from 'features/challenge/config';
import {
  IChallenge,
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import { ICoachingTool } from 'features/coachingTool';
import { CoachingTool } from 'features/coachingTool/CoachingTool';
import { SPRINT_SOURCE_TYPES } from 'features/sprint/config';

import {
  IDraftSprint,
  IDraftSprintProps,
  SprintSourceType,
} from './config/types';

export class DraftSprint implements IDraftSprint {
  challenges: IRepeatedChallenge[];
  oneTimeChallenges: INonRepeatedChallenge[];
  weeksCount?: number;
  readonly source?: { qssId: string };
  readonly sprintType: SprintSourceType;
  title: string;
  readonly userEmail?: string;
  readonly userName?: string;
  readonly userPicture?: any;
  readonly updatedAt: string;
  readonly sprintId: string;
  coachingTools: ICoachingTool[];

  constructor(sprint: IDraftSprintProps) {
    this.challenges =
      sprint.challenges?.map((challenge) => new RepeatedChallenge(challenge)) ||
      [];
    this.oneTimeChallenges =
      sprint.oneTimeChallenges?.map(
        (challenge) => new NonRepeatedChallenge(challenge)
      ) || [];
    this.source = sprint.source;
    this.sprintType = sprint.sprintType;
    this.title = sprint.title;
    this.userEmail = sprint.userEmail;
    this.userName = sprint.userName;
    this.userPicture = sprint.userPicture;
    this.weeksCount = sprint.weeksCount;
    this.updatedAt = sprint.updatedAt;
    this.sprintId = sprint.sprintId || 'draft';
    this.coachingTools = sprint.coachingTools.map(
      (coachingTool) => new CoachingTool(coachingTool)
    );
  }

  get allChallenges(): (IRepeatedChallenge | INonRepeatedChallenge)[] {
    return [...this.challenges, ...this.oneTimeChallenges];
  }

  get availableChallengeFields() {
    return Object.values(CHALLENGE_FIELDS).reduce((challengeFields, field) => {
      const challenges = this[field];
      challengeFields[field] = challenges.length < MAX_SPRINT_CHALLENGES_COUNT;

      return challengeFields;
    }, {} as Record<typeof CHALLENGE_FIELDS[keyof typeof CHALLENGE_FIELDS], boolean>);
  }

  get challengesCount() {
    return this.allChallenges.length;
  }

  get challengesCountLabel() {
    const challengesCount = this.challengesCount;

    return `${challengesCount} ${
      challengesCount === 1 ? 'challenge' : 'challenges'
    }`;
  }

  get formattedDate() {
    return null;
  }

  get hasChallenges(): boolean {
    return this.challengesCount > 0;
  }

  get isReadyToStart() {
    return Boolean(this.challengesCount > 0 && this.title);
  }

  get sprintSource(): { qssId: string } | undefined {
    return this.source;
  }

  get sprintTypeLabel(): string {
    let label = '';

    switch (this.sprintType) {
      case SPRINT_SOURCE_TYPES.TRADITIONAL:
        label = 'Traditional';
        break;
      case SPRINT_SOURCE_TYPES.QSS:
        label = 'QSS';
        break;
      case SPRINT_SOURCE_TYPES.QSS_EDITED:
        label = 'QSS • Edited';
        break;
      default:
        label = 'No sprint type';
    }

    return label;
  }

  get state(): 'draft' {
    return 'draft';
  }

  get titleWithStateLabel() {
    return 'Bridge Mode';
  }

  getChallengesByType() {
    return Object.values(CHALLENGE_FIELDS).reduce(
      (challenges, challengesField) => {
        challenges[challengesField] = this[challengesField] || [];

        return challenges;
      },
      {} as Record<
        typeof CHALLENGE_FIELDS[keyof typeof CHALLENGE_FIELDS],
        IChallenge[]
      >
    );
  }
}
