import { If, Then, Else } from 'react-if';

import { useFeatureFlag } from 'featureFlags/hooks';

type RenderIfFFEnabledProps = {
  featureFlag: string;
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

export const RenderIfFFEnabled = ({
  featureFlag,
  children,
  fallback = null,
}: RenderIfFFEnabledProps) => {
  const isFeatureEnabled = useFeatureFlag(featureFlag);

  return (
    <If condition={isFeatureEnabled}>
      <Then>{children}</Then>
      <Else>{fallback}</Else>
    </If>
  );
};
