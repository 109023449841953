import { useNavigate } from 'react-router-dom';

import { openJournalSidebar } from 'features/journal';
import { ParticipantTableNotesButton } from 'features/participants/components/Table/Actions/Notes/Button';
import {
  ParticipantsTableLatestSprint,
  ParticipantsTableUserInfo,
} from 'features/participants/config/types';
import { useTimelineOrSprintNavigationLink } from 'features/timeline/hooks/useTimelineOrSprintNavigationLink';
import { useUserContext } from 'features/user/context';

type ParticipantsTableNotesPropTypes = {
  unreadNotesRepliesNumber: number;
  latestSprint: ParticipantsTableLatestSprint;
  userInfo: ParticipantsTableUserInfo;
};

export const ParticipantsTableJournalNotes = ({
  unreadNotesRepliesNumber,
  latestSprint,
  userInfo,
}: ParticipantsTableNotesPropTypes) => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const sprintLink = useTimelineOrSprintNavigationLink({
    sprintId: latestSprint.sprintId,
    userEmail: userInfo.email,
  });

  const handleNotesButtonClick = () => {
    if (latestSprint?.sprintId) {
      navigate(sprintLink, {
        state: {
          timelineStartDate: latestSprint.startDate,
        },
      });
    }

    openJournalSidebar({
      user,
      participantId: userInfo.userId,
      participantEmail: userInfo.email,
    });
  };

  return (
    <ParticipantTableNotesButton
      onClick={handleNotesButtonClick}
      unreadCount={unreadNotesRepliesNumber}
    />
  );
};
