import { memo } from 'react';
import { Else, If, Then } from 'react-if';
import { For } from 'react-loops';

import { produce } from 'immer';
import { usePinnedColumnsContext } from 'shared';

import { PlanningSprintModel } from 'features/planning';
import { SPRINT_STATES } from 'features/sprint/config';
import { useSprintsQuery } from 'features/sprint/hooks';

import { PlanningActiveSprint } from './ActiveSprint';
import { PlanningFinishedSprint } from './FinishedSprint';

export const PlanningActiveFinishedSprints = memo(
  ({ pinned }: { pinned: boolean }) => {
    const { pinnedColumns } = usePinnedColumnsContext();
    const { data: activeFinishedSprintsData } =
      useSprintsQuery<PlanningSprintModel[]>();

    const filteredSprints = produce(activeFinishedSprintsData, (draft) => {
      draft?.reverse();
    })?.filter((sprint) => {
      return pinned
        ? pinnedColumns.has(sprint.sprintId)
        : !pinnedColumns.has(sprint.sprintId);
    });

    return (
      <For of={filteredSprints}>
        {(sprint: PlanningSprintModel, { isLast, index }) => {
          return (
            <If condition={sprint.state === SPRINT_STATES.ACTIVE}>
              <Then>
                <PlanningActiveSprint
                  key={sprint.challenges.length}
                  sprintId={sprint.sprintId}
                  sprintTitle={sprint.title}
                  challenges={sprint.challenges}
                  coachingTools={sprint.coachingTools}
                  sprintFormattedDate={sprint.formattedDate}
                  sprintIndex={index}
                  sprintWeeksCount={sprint.weeksCount}
                  sprintDurationInWeeks={sprint.durationInWeeks}
                  sprintRestartedMetaData={sprint.sprintRestartedMetaData}
                  sprintStartDate={sprint.startDate}
                />
              </Then>
              <Else>
                <PlanningFinishedSprint
                  sprintId={sprint.sprintId}
                  sprintTitle={sprint.title}
                  challenges={sprint.challenges}
                  coachingTools={sprint.coachingTools}
                  sprintFormattedDate={sprint.formattedDate}
                  shouldScrollIntoView={isLast}
                  sprintIndex={index}
                  sprintWeeksCount={sprint.weeksCount}
                  sprintDurationInWeeks={sprint.durationInWeeks}
                  sprintRestartedMetaData={sprint.sprintRestartedMetaData}
                  sprintFinished={sprint.finished}
                  sprintStartDate={sprint.startDate}
                />
              </Else>
            </If>
          );
        }}
      </For>
    );
  }
);
