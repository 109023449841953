import { useFormContext, useWatch } from 'react-hook-form';

import {
  CHALLENGE_DIFFICULTY_RATINGS,
  difficultyRatingOptions,
} from 'features/challenge/config';
import { ChallengeDifficultyRating } from 'features/challenge/config/types';

export const useDifficultyRatingFieldConfig = () => {
  const { setValue } = useFormContext();
  const difficultyRating = useWatch({ name: 'difficultyRating' });

  const onChange = (value: ChallengeDifficultyRating) => {
    setValue('difficultyRating', value);
  };

  return {
    value: difficultyRating || CHALLENGE_DIFFICULTY_RATINGS.MEDIUM,
    onChange,
    options: difficultyRatingOptions,
  };
};
