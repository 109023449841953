import { ButtonIcon, VARIANTS } from 'shared';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';

import { PlanningSprintModel } from 'features/planning/Sprint';
import { SPRINT_STATES } from 'features/sprint/config';
import { SprintSourceType } from 'features/sprint/config/types';
import { useSprintsQuery } from 'features/sprint/hooks/query/sprints/useSprints';
import useSprintStart from 'features/sprint/hooks/useSprintStart/useSprintStart';

export const PlanningDraftSprintHeaderStartButton = ({
  sprintTitle,
  sprintType,
  sprintChallengesCount,
  sprintId,
}: {
  sprintTitle: string;
  sprintType: SprintSourceType;
  sprintChallengesCount: number;
  sprintId: string;
}) => {
  const { isSprintReadyToStart, onStartSprintButtonClick } = useSprintStart({
    sprintId,
    sprintType,
    sprintChallengesCount,
    sprintTitle,
  });
  const { data: sprints, isSuccess } = useSprintsQuery<PlanningSprintModel[]>();
  const hasActiveSprint =
    isSuccess &&
    sprints.length > 0 &&
    sprints[0].state === SPRINT_STATES.ACTIVE;

  if (hasActiveSprint || !isSprintReadyToStart) {
    return null;
  }

  return (
    <ButtonIcon
      ariaLabel="start sprint"
      icon={<Icon name={iconNames.play} width={16} height={16} />}
      onClick={onStartSprintButtonClick}
      variant={VARIANTS.TERTIARY}
      dataTestid="start-sprint-button"
    />
  );
};
