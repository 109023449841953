import { useNavigate } from 'react-router-dom';

import { iconNames } from 'shared_DEPRECATED/components/Icon';

import { openNotesSidebar } from 'features/challengeNotes/utils';
import { ParticipantsTableActionDisabled } from 'features/participants/components/Table/Actions/';
import { ParticipantTableNotesButton } from 'features/participants/components/Table/Actions/Notes/Button';
import {
  ParticipantsTableLatestSprint,
  ParticipantsTableUserInfo,
} from 'features/participants/config/types';
import { useTimelineOrSprintNavigationLink } from 'features/timeline/hooks/useTimelineOrSprintNavigationLink';
import { useUserContext } from 'features/user/context';

type ParticipantsTableNotesPropTypes = {
  unreadNotes?: number;
  latestSprint: ParticipantsTableLatestSprint;
  userInfo: ParticipantsTableUserInfo;
};

export const ParticipantsTableNotes = ({
  unreadNotes,
  latestSprint,
  userInfo,
}: ParticipantsTableNotesPropTypes) => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const sprintLink = useTimelineOrSprintNavigationLink({
    sprintId: latestSprint.sprintId,
    userEmail: userInfo.email,
  });

  const handleNotesButtonClick = () => {
    if (latestSprint?.sprintId) {
      navigate(sprintLink, {
        state: {
          timelineStartDate: latestSprint.startDate,
        },
      });
    }

    openNotesSidebar({
      sprint: latestSprint,
      user,
    });
  };

  return typeof unreadNotes === 'number' ? (
    <ParticipantTableNotesButton
      onClick={handleNotesButtonClick}
      unreadCount={unreadNotes}
    />
  ) : (
    <ParticipantsTableActionDisabled
      label="User doesn't have an active or past sprints"
      icon={iconNames.message}
    />
  );
};
