import { useLocation, useParams } from 'react-router-dom';

import { getPathParams } from 'shared_DEPRECATED/utils';
import { routeNames } from 'shared_DEPRECATED/utils/config';

import { sharedSprintLinks } from 'features/sprint/utils';

const SPRINT_URL_RESOLVER_MAP = {
  [routeNames.COACH]: (sprintId: string, email: string) =>
    `${sharedSprintLinks.participants}/${email}/${sprintId}`,
  [routeNames.CONNECTIONS]: (sprintId: string, email: string) =>
    `/users/${email}/sprints/${sprintId}`,
};

const getSprintLink =
  (email: string, pathname: string) => (sprintId: string) => {
    let [firstPathParam] = getPathParams(pathname);

    const sprintUrlResolver = SPRINT_URL_RESOLVER_MAP[firstPathParam];

    return (
      sprintUrlResolver?.(sprintId, email) ||
      `/${routeNames.SPRINTS_DASHBOARD}/${sprintId}`
    );
  };

// ** Function overloading to maintain type inference, eliminating the need for `as string`
// ** or explicit return types, and preventing type assertions at the hook invocation level.

export function useSprintLink(sprintId: string, userEmail?: string): string;
export function useSprintLink(): (
  sprintId: string,
  userEmail?: string
) => string;

export function useSprintLink(sprintId?: string, userEmail?: string) {
  const { email } = useParams();
  const { pathname } = useLocation();

  const resolveSprintLink = getSprintLink(userEmail || email!, pathname);

  return sprintId ? resolveSprintLink(sprintId) : resolveSprintLink;
}
