import { JOURNAL_FEATURE_FLAG, RenderIfFFEnabled } from 'featureFlags';
import { Flex } from 'shared_DEPRECATED/components/Flex';

import {
  ParticipantsTableMessages,
  ParticipantsTableNextSessionHint,
  ParticipantsTableNotes,
} from 'features/participants/components/Table/Actions/';
import {
  CoachingMode,
  ParticipantsTableRowDataType,
} from 'features/participants/config/types';

import { ParticipantsTableJournalNotes } from './Actions/JournalNotes';

type ParticipantsTableActionsCellPropTypes = {
  value: CoachingMode;
  row: ParticipantsTableRowDataType;
};

export const ParticipantsTableActionsCell = ({
  row,
}: ParticipantsTableActionsCellPropTypes) => {
  const {
    nextCoachingSessionAppointment,
    userInfo,
    latestSprint,
    unreadNotesNumber,
    chatId,
    unreadChatMessagesNumber,
    unreadNotesRepliesNumber,
    isChatMarkedAsUnread,
  } = row.original;

  return (
    <Flex gap="1rem" justifyContent="flex-start">
      <ParticipantsTableNextSessionHint
        appointmentId={nextCoachingSessionAppointment?.appointmentId}
        userInfo={userInfo}
      />
      <RenderIfFFEnabled
        featureFlag={JOURNAL_FEATURE_FLAG}
        fallback={
          <ParticipantsTableNotes
            userInfo={userInfo}
            unreadNotes={unreadNotesNumber}
            latestSprint={latestSprint}
          />
        }
      >
        <ParticipantsTableJournalNotes
          userInfo={userInfo}
          latestSprint={latestSprint}
          unreadNotesRepliesNumber={unreadNotesRepliesNumber}
        />
      </RenderIfFFEnabled>
      <ParticipantsTableMessages
        userInfo={userInfo}
        latestSprint={latestSprint}
        chatId={chatId}
        unreadChatMessagesNumber={unreadChatMessagesNumber}
        isChatMarkedAsUnread={isChatMarkedAsUnread}
      />
    </Flex>
  );
};
