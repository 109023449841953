import {
  PLANNING_START_SPRINT_BUTTON_FEATURE_FLAG,
  RenderIfFFEnabled,
} from 'featureFlags';
import {
  BoardColumnPinButton,
  ButtonGroup,
  ButtonIcon,
  Typography,
  VARIANTS,
} from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  IPlanningDraftSprint,
  PlanningDraftSprintDeleteButton,
  PlanningDraftSprintHeaderMenu,
} from 'features/planning';
import { PlanningDraftSprintHeaderStartButton } from 'features/planning/components/Board/DraftSprint/Header/StartButton';

type TPlanningDraftSprintHeaderViewer = {
  sprint: IPlanningDraftSprint;
  onEdit: () => void;
};

export const PlanningDraftSprintHeaderViewer = ({
  sprint,
  onEdit,
}: TPlanningDraftSprintHeaderViewer) => {
  return (
    <>
      <Flex>
        <Typography color="black" fontWeight="semiBold" type="meta">
          {sprint.title}
        </Typography>

        <Spacer size="sm" />
        <ButtonIcon
          ariaLabel="edit"
          icon={<Icon name={iconNames.pen} width={14} height={14} />}
          dataTestid="edit-btn"
          variant={VARIANTS.TERTIARY}
          onClick={onEdit}
          isRound
        />
      </Flex>
      <ButtonGroup spacing="0.5rem" size="sm">
        <RenderIfFFEnabled
          featureFlag={PLANNING_START_SPRINT_BUTTON_FEATURE_FLAG}
        >
          <PlanningDraftSprintHeaderStartButton
            sprintTitle={sprint.title}
            sprintType={sprint.sprintType}
            sprintChallengesCount={sprint.challengesCount}
            sprintId={sprint.sprintId}
          />
        </RenderIfFFEnabled>
        <BoardColumnPinButton columnId={sprint.sprintId} />
        <PlanningDraftSprintDeleteButton sprint={sprint} />
        <PlanningDraftSprintHeaderMenu sprint={sprint} />
      </ButtonGroup>
    </>
  );
};
