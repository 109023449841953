import { useMemo } from 'react';

import { MomentumSidebar } from 'features/momentum/components/Sidebar/Sidebar';
import {
  IDraftSprint,
  ISprint,
  SprintsType,
} from 'features/sprint/config/types';
import { getSprintsWithoutDraft } from 'features/sprint/utils';
import { SprintTimelineItem } from 'features/timeline/SprintTimelineItem';

import { useOpenSidebar } from 'shared/components/Sidebar';

export const useMomentumSidebar = ({
  sprint,
  sprints,
}: {
  sprint: ISprint | IDraftSprint | SprintTimelineItem;
  sprints: SprintsType;
}) => {
  const sprintsWithoutDrafts = useMemo(
    () => getSprintsWithoutDraft(sprints),
    [sprints]
  );

  const { openSidebar } = useOpenSidebar({
    content: <MomentumSidebar />,
    props: { title: 'Momentum Feed' },
    context: {
      sprint: sprint,
      sprints: sprintsWithoutDrafts,
    },
  });

  return { openSidebar };
};
