import { lazy, Suspense } from 'react';

import {
  LayoutPageContent,
  LayoutPageSidebar,
} from 'app/layout/components/page';
import { SeoTitle } from 'app/seo/components/Title';
import { PlanningWithFeatureFlag } from 'featureFlags/components/PlanningFeatureFlag';
import Box from 'shared_DEPRECATED/components/Box';
import Loader from 'shared_DEPRECATED/components/Loader';
import { routeNames } from 'shared_DEPRECATED/utils/config';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { CurrentSprintProvider } from 'features/sprint/context/currentSprint';

const SharedLayoutSidebar = lazy(() =>
  lazyRetry(
    () => import('app/layout/components/Layout/AccountabilitySidebar'),
    'AccountabilitySidebar'
  )
);

const ParticipantLayoutSidebar = lazy(() =>
  lazyRetry(
    () => import('app/layout/components/Layout/ParticipantSidebar'),
    'ParticipantSidebar'
  )
);

const MySprintsContent = lazy(() =>
  lazyRetry(
    () => import('features/sprint/components/Content/MySprintsContent'),
    'MySprintsContent'
  )
);

const SprintForm = lazy(() =>
  lazyRetry(
    () => import('features/sprint/components/Form/SprintForm'),
    'SprintForm'
  )
);

const ParticipantSessionsPage = lazy(() =>
  lazyRetry(
    () => import('app/pages/participant/Sessions'),
    'ParticipantSessions'
  )
);

const ParticipantDashboardPageWrapper = lazy(() =>
  lazyRetry(() => import('app/pages/participant/Dashboard'), 'Dashboard')
);

const ParticipantDashboardPage = lazy(() =>
  lazyRetry(
    () => import('app/pages/Assessments/Assessments'),
    'ParticipantDashboard'
  )
);

const Goals = lazy(() =>
  lazyRetry(() => import('app/pages/Goals/Goals'), 'Goals')
);

const GoalPage = lazy(() =>
  lazyRetry(() => import('app/pages/Goal/Goal'), 'Goal')
);

const ParticipantGroupsPage = lazy(() =>
  lazyRetry(() => import('app/pages/participant/Groups'), 'Groups')
);

const SharedDashboardPage = lazy(() =>
  lazyRetry(
    () => import('app/pages/participant/SharedDashboard'),
    'SharedDashboard'
  )
);

const SharedSprintsContent = lazy(() =>
  lazyRetry(
    () => import('features/sprint/components/Content/SharedSprintsContent'),
    'SharedSprintsContent'
  )
);

const TimelinePage = lazy(() =>
  lazyRetry(() => import('app/pages/participant/Timeline'), 'Timeline')
);

export const participantRoutes = [
  {
    path: routeNames.SPRINTS_DASHBOARD,
    element: (
      <Suspense fallback={<Loader />}>
        <CurrentSprintProvider>
          <ParticipantDashboardPageWrapper />
        </CurrentSprintProvider>
      </Suspense>
    ),
    children: [
      {
        path: ':sprintId',
        element: (
          <>
            <SeoTitle title="My Sprint Details" />
            <LayoutPageSidebar>
              <Suspense fallback={<Loader />}>
                <ParticipantLayoutSidebar />
              </Suspense>
            </LayoutPageSidebar>
            <LayoutPageContent>
              <Suspense fallback={<Loader />}>
                <MySprintsContent />
              </Suspense>
            </LayoutPageContent>
          </>
        ),
      },
      {
        path: ':sprintId/edit',
        element: (
          <>
            <SeoTitle title="My Sprint Edit" />
            <Suspense fallback={<Loader />}>
              <SprintForm />
            </Suspense>
          </>
        ),
      },
    ],
  },
  {
    path: `/${routeNames.CHALLENGE_TRACKING}`,
    element: (
      <>
        <SeoTitle title="My Timeline" />
        <Suspense fallback={<Loader />}>
          <TimelinePage />
        </Suspense>
      </>
    ),
  },
  {
    path: 'sessions',
    element: (
      <>
        <SeoTitle title="My Coach Sessions" />
        <Suspense fallback={<Loader />}>
          <ParticipantSessionsPage />
        </Suspense>
      </>
    ),
  },
  {
    path: 'dashboard',
    element: (
      <>
        <SeoTitle title="My Dashboard" />
        <Suspense fallback={<Loader />}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              height: '100%',
              width: '100%',
              maxWidth: '100vw',
            }}
          >
            <LayoutPageSidebar>
              <ParticipantLayoutSidebar />
            </LayoutPageSidebar>
            <LayoutPageContent>
              <ParticipantDashboardPage />
            </LayoutPageContent>
          </Box>
        </Suspense>
      </>
    ),
  },
  {
    path: routeNames.PLANNING,
    element: (
      <>
        <SeoTitle title="My Planning" />
        <Suspense fallback={<Loader />}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              height: '100%',
              width: '100%',
              maxWidth: '100vw',
            }}
          >
            <LayoutPageSidebar>
              <ParticipantLayoutSidebar />
            </LayoutPageSidebar>
            <LayoutPageContent>
              <CurrentSprintProvider>
                <PlanningWithFeatureFlag />
              </CurrentSprintProvider>
            </LayoutPageContent>
          </Box>
        </Suspense>
      </>
    ),
  },
  {
    path: routeNames.GOALS,
    element: (
      <>
        <SeoTitle title="My Goals" />
        <Suspense fallback={<Loader />}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              height: '100%',
              width: '100%',
            }}
          >
            <LayoutPageSidebar>
              <ParticipantLayoutSidebar />
            </LayoutPageSidebar>
            <LayoutPageContent>
              <Goals />
            </LayoutPageContent>
          </Box>
        </Suspense>
      </>
    ),
  },
  {
    path: `${routeNames.GOALS}/:goalId`,
    element: (
      <>
        <SeoTitle title="My Goal Details" />
        <Suspense fallback={<Loader />}>
          <GoalPage />
        </Suspense>
      </>
    ),
  },
  {
    path: routeNames.GROUPS,
    element: (
      <>
        <SeoTitle title="My Groups" />
        <Suspense fallback={<Loader />}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              height: '100%',
              width: '100%',
            }}
          >
            <LayoutPageSidebar>
              <ParticipantLayoutSidebar />
            </LayoutPageSidebar>
            <LayoutPageContent>
              <ParticipantGroupsPage />
            </LayoutPageContent>
          </Box>
        </Suspense>
      </>
    ),
  },
  {
    path: `${routeNames.CONNECTIONS}/:email`,
    element: (
      <Suspense fallback={<Loader />}>
        <CurrentSprintProvider>
          <SharedDashboardPage />
        </CurrentSprintProvider>
      </Suspense>
    ),
    children: [
      {
        path: 'sprints/:sprintId',
        element: (
          <>
            <SeoTitle title="My Group Mate Sprint" />
            <Suspense fallback={<Loader />}>
              <LayoutPageSidebar>
                <SharedLayoutSidebar />
              </LayoutPageSidebar>
              <LayoutPageContent>
                <SharedSprintsContent />
              </LayoutPageContent>
            </Suspense>
          </>
        ),
      },
    ],
  },
];
