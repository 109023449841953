import { Nullable } from 'shared_DEPRECATED/types';
import { Picture } from 'shared_DEPRECATED/types/Picture';
import { RequireAtLeastOne } from 'shared_DEPRECATED/types/RequireAtLeastOne';
import { DAILY_STATUSES } from 'shared_DEPRECATED/utils/config';
import { ObjectValues, ProcessingStatus } from 'types/common';

import { Goal } from 'features/goal/config/types';

import {
  CHALLENGE_DIFFICULTY_RATINGS,
  CHALLENGE_FIELD_TYPES,
  CHALLENGE_LOCATIONS,
  TARGET_TYPES,
} from './index';

export type WeekdayTargetType = {
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
};

export type WeeklyTargetType = number[];

export type DueDateTargetType = string;

export type NoteMediaType = {
  blurhash: string;
  contentLength: number;
  contentType: string;
  mediaId: string;
  mediaType: string;
  processingStatus: ProcessingStatus;
  thumbnail: Nullable<{
    blurhash: string;
    contentLength: number;
    contentType: number;
    height: number;
    url: string;
    width: number;
  }>;
  url: string;
};

export type DailyStatusType = {
  [key: string]: {
    amount: Nullable<number>;
    amountChangeValue: Nullable<number>;
    amountTotal: number;
    noteIds: string[];
    status: typeof DAILY_STATUSES[keyof typeof DAILY_STATUSES];
    notes: {
      media: NoteMediaType[];
    }[];
  };
};

export type ChallengeGoalsType = Goal[];

export type ChallengeTargetAmountType = {
  value: number;
  unit: string;
  automation: Nullable<string>;
};

export enum TARGET_PERIOD {
  PER_DAY = 'per_day',
  PER_WEEK = 'per_week',
  PER_SPRINT = 'per_sprint',
}

export type TargetPeriodNullable = Nullable<TARGET_PERIOD>;

export type ChallengeTargetAmountTypeNullable =
  Nullable<ChallengeTargetAmountType>;

export type WeekdayTargetProgressType = {
  type: typeof TARGET_TYPES.WEEKDAY;
  weekday: WeekdayTargetType;
  dailyStatus: DailyStatusType;
  amount: ChallengeTargetAmountTypeNullable;
  period: TargetPeriodNullable;
};

export type WeeklyTargetProgressType = {
  type: typeof TARGET_TYPES.WEEKLY;
  weekly: WeeklyTargetType;
  dailyStatus: DailyStatusType;
  amount: ChallengeTargetAmountTypeNullable;
  period: TargetPeriodNullable;
};

export type WeekdayTargetProgressTypeWithAmount = {
  type: typeof TARGET_TYPES.WEEKDAY;
  weekday: WeekdayTargetType;
  dailyStatus: DailyStatusType;
  amount: ChallengeTargetAmountType;
  period: TARGET_PERIOD;
};

export type WeeklyTargetProgressTypeWithAmount = {
  type: typeof TARGET_TYPES.WEEKLY;
  weekly: WeeklyTargetType;
  dailyStatus: DailyStatusType;
  amount: ChallengeTargetAmountType;
  period: TARGET_PERIOD;
};

export type DueDateTargetProgressType = {
  type: typeof TARGET_TYPES.DUE_DATE;
  dueDate: DueDateTargetType;
  status: typeof DAILY_STATUSES[keyof typeof DAILY_STATUSES];
  statusSetOn?: string;
};

export type TChallengeLocation =
  typeof CHALLENGE_LOCATIONS[keyof typeof CHALLENGE_LOCATIONS];

export interface IBaseChallenge {
  description: string;
  dimensions: string[];
  difficultyRating: ChallengeDifficultyRating;
  emoji: Nullable<string>;
  endDate?: string;
  passes?: number;
  picture: Nullable<Picture>;
  source: {
    curatedChallengeId: Nullable<string>;
    customChallengeId: Nullable<string>;
  };
  sprintChallengeId: string;
  goals: ChallengeGoalsType;
  startDate?: string;
  title: string;
  tags: string[];
  isLatestChallengeVersion: boolean;
  private: boolean;
  proofRequired: boolean;
  reminder: Nullable<string>;
  challengeId: string;
  customChallengeId?: string;
  sprintId?: string;
  location?: TChallengeLocation;
}

export interface IBaseChallengeComputedProps {
  type: typeof CHALLENGE_FIELD_TYPES[keyof typeof CHALLENGE_FIELD_TYPES];
}

export type ChallengeTargetProgressType =
  | WeekdayTargetProgressType
  | WeeklyTargetProgressType
  | DueDateTargetProgressType;

export interface IChallenge extends IBaseChallenge {
  target: ChallengeTargetProgressType;
}

export interface IRepeatedChallenge extends IBaseChallenge {
  target: WeekdayTargetProgressType | WeeklyTargetProgressType;
}

export interface INonRepeatedChallenge extends IBaseChallenge {
  target: DueDateTargetProgressType;
}

type PictureProps = {
  pictureUrl?: string;
  emoji?: Nullable<string>;
  pictureFileUrl?: string;
};

export type RequireEmojiOrPicture = RequireAtLeastOne<
  PictureProps,
  'pictureUrl' | 'emoji' | 'pictureFileUrl'
>;

export interface GoalOption {
  goalId: string;
  title: string;
  isSelected: boolean;
  prioritized: boolean;
}
export interface GoalsDataResult {
  goalOptions: GoalOption[];
  selectedGoalIds: string[];
}

export type TChallengeDailyAmountMutationPostType = {
  challengeId: string;
  date: string;
  amount: number;
};

export type ChallengeDifficultyRating = ObjectValues<typeof CHALLENGE_DIFFICULTY_RATINGS>;
