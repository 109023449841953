import { useEffect } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import { Checkbox, FormControl, FormErrorMessage, TextInput } from 'shared';
import Box from 'shared_DEPRECATED/components/Box';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { CHALLENGE_FIELDS } from 'features/challenge/config';
import { SprintFormChallengeList } from 'features/sprint/components/Form/SprintFormChallengeList';
import SprintFormEmptyState from 'features/sprint/components/Form/SprintFormEmptyState';
import {
  useEditSprintMutation,
  useSprintFormData,
} from 'features/sprint/hooks';
import { isDraftSprint, mapSprintFormData } from 'features/sprint/utils';
import { useTimelineOrSprintNavigationLink } from 'features/timeline/hooks/useTimelineOrSprintNavigationLink';
import { useUserContext } from 'features/user/context';

import SprintFormHeaderAutosave from './SprintFormHeaderAutosave';

const SprintForm = () => {
  const { user } = useUserContext();
  const { sprint } = useOutletContext();
  const isSprintDraft = isDraftSprint(sprint.state);

  const sprintLink = useTimelineOrSprintNavigationLink({
    sprintId: sprint.sprintId,
  });

  const { trigger, ...sprintFormMethods } = useForm({
    defaultValues: mapSprintFormData(sprint),
    mode: 'onChange',
  });

  // Need to reset the form with updated sprint in order to avoid backend errors related to outdated challenges
  useEffect(
    () => {
      sprintFormMethods.reset({ ...mapSprintFormData(sprint) });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sprint]
  );

  const sprintFormData = useSprintFormData(sprintFormMethods);

  const repeatedChallengesField = useFieldArray({
    control: sprintFormMethods.control,
    name: CHALLENGE_FIELDS.CHALLENGES,
  });
  const oneTimeChallengesField = useFieldArray({
    control: sprintFormMethods.control,
    name: CHALLENGE_FIELDS.ONE_TIME_CHALLENGES,
  });

  const challengesCount = sprintFormData.challengesCount;

  const mutation = useEditSprintMutation({ sprintId: sprint.sprintId });

  const title = useWatch({
    control: sprintFormMethods.control,
    name: 'title',
  });

  useEffect(
    () => {
      trigger();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Flex
      as="article"
      justifyContent="center"
      alignItems="flex-start"
      width="100%"
    >
      <FormProvider
        {...{
          ...sprintFormMethods,
          challenges: repeatedChallengesField,
          oneTimeChallenges: oneTimeChallengesField,
        }}
      >
        <Box
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <Spacer size="xlg xxlg">
            <Spacer size="lg xxlg">
              <Spacer size="zr xlg">
                <form>
                  <SprintFormHeaderAutosave
                    sprint={sprintFormData}
                    sprintPath={sprintLink}
                    onSubmit={mutation.mutateAsync}
                  />
                  <Flex gap="1.5rem">
                    <FlexItem flexGrow={1}>
                      <FormControl
                        isInvalid={sprintFormMethods.formState.errors.title}
                        isRequired={true}
                      >
                        <TextInput
                          size="lg"
                          value={title}
                          {...sprintFormMethods.register('title', {
                            required: 'This field is required',
                          })}
                        />
                        <FormErrorMessage>
                          {sprintFormMethods.formState.errors.title?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </FlexItem>
                    {!isSprintDraft && user.isCoach && (
                      <FlexItem>
                        <Checkbox
                          label="With coach"
                          dataTestid="check-coached-sprint"
                          {...sprintFormMethods.register('isCoached')}
                        />
                      </FlexItem>
                    )}
                  </Flex>
                  <FormErrorMessage fieldName="title" />
                  <Spacer size="zr zr lg" />
                  <Spacer size="zr zr lg" />
                  {challengesCount > 0 ? (
                    Object.values(CHALLENGE_FIELDS).map((challengesField) => (
                      <SprintFormChallengeList
                        key={challengesField}
                        challengesField={challengesField}
                      />
                    ))
                  ) : (
                    <SprintFormEmptyState />
                  )}
                  <Spacer size="zr zr lg" />
                </form>
              </Spacer>
            </Spacer>
          </Spacer>
        </Box>
      </FormProvider>
    </Flex>
  );
};

export default SprintForm;
