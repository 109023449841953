import PropTypes from 'prop-types';

import Box from 'shared_DEPRECATED/components/Box';

export const LayoutPageContent = ({ children }) => {
  return (
    <Box
      style={{
        boxSizing: 'border-box',
        paddingBottom: 'var(--bottom-fixed-area-height)',
        height: '100%',
        position: 'relative',
        overflowX: 'hidden',
        flexGrow: 1,
      }}
    >
      {children}
    </Box>
  );
};

LayoutPageContent.propTypes = { children: PropTypes.node.isRequired };
