import { iconNames } from 'shared_DEPRECATED/components/Icon';
import { SPACER_SIZES } from 'shared_DEPRECATED/components/Spacer';
import { DAILY_STATUS_VALUES } from 'shared_DEPRECATED/utils/config';

export const DIMENSIONS = Object.freeze({
  CAREER: 'Career',
  HEALTH: 'Health',
  ENJOYMENT: 'Enjoyment',
  RELATIONSHIPS: 'Relationships',
  FINANCES: 'Finances',
  IMPACT: 'Impact',
  SPIRITUALITY: 'Spirituality',
});

export const DIMENSIONS_VALUES: string[] = Object.values(DIMENSIONS).map(
  (value) => value.toLowerCase()
);

export const TARGET_TYPES = {
  WEEKDAY: 'weekday',
  WEEKLY: 'weekly',
  DUE_DATE: 'due_date',
};

export const TARGET_TYPES_KEYS = {
  WEEKDAY: 'weekday',
  WEEKLY: 'weekly',
  DUE_DATE: 'dueDate',
} as const;

export const CHALLENGE_FIELDS = Object.freeze({
  CHALLENGES: 'challenges',
  ONE_TIME_CHALLENGES: 'oneTimeChallenges',
} as const);

export const CHALLENGE_SECTION_TITLES = Object.freeze({
  [CHALLENGE_FIELDS.CHALLENGES]: 'Repeated challenges',
  [CHALLENGE_FIELDS.ONE_TIME_CHALLENGES]: 'One-time challenges',
});

export const CHALLENGE_TYPES = Object.freeze({
  REPEATING: 'repeating',
  NON_REPEATING: 'non-repeating',
});

export const CHALLENGE_TYPE_FIELDS = Object.freeze({
  [CHALLENGE_TYPES.REPEATING]: CHALLENGE_FIELDS.CHALLENGES,
  [CHALLENGE_TYPES.NON_REPEATING]: CHALLENGE_FIELDS.ONE_TIME_CHALLENGES,
});

export const CHALLENGE_FIELD_TYPES = Object.freeze({
  [CHALLENGE_FIELDS.CHALLENGES]: CHALLENGE_TYPES.REPEATING,
  [CHALLENGE_FIELDS.ONE_TIME_CHALLENGES]: CHALLENGE_TYPES.NON_REPEATING,
});

export const INITIAL_FREQUENCY = {
  weekly: null,
  weekday: null,
  dueDate: null,
};

export const TARGET_DATE_TYPES = {
  IN_TIME_WITH_STATUS: 'IN_TIME_WITH_STATUS',
  IN_TIME_WITHOUT_STATUS: 'IN_TIME_WITHOUT_STATUS',
  TODAY_WITHOUT_STATUS: 'TODAY_WITHOUT_STATUS',
  LATE: 'LATE',
};

export const MIN_SPRINT_CHALLENGES_COUNT = 1;
export const MAX_SPRINT_CHALLENGES_COUNT = 100;

export const CHECKBOX_BUTTONS_GAP = SPACER_SIZES.md;

export const WEEKDAY_NAMES = {
  MON: 'mon',
  TUE: 'tue',
  WED: 'wed',
  THU: 'thu',
  FRI: 'fri',
  SAT: 'sat',
  SUN: 'sun',
} as const;

export const DAY_VALUE_MAP = {
  [WEEKDAY_NAMES.MON]: 2,
  [WEEKDAY_NAMES.TUE]: 4,
  [WEEKDAY_NAMES.WED]: 8,
  [WEEKDAY_NAMES.THU]: 16,
  [WEEKDAY_NAMES.FRI]: 32,
  [WEEKDAY_NAMES.SAT]: 64,
  [WEEKDAY_NAMES.SUN]: 128,
};

const EVERYDAY = 'Everyday';
const WEEKDAYS = 'Weekdays';
const WEEKENDS = 'Weekends';

const WEEKDAY_TYPES: {
  [key: string]: string;
} = {
  [WEEKDAY_NAMES.MON]: WEEKDAYS,
  [WEEKDAY_NAMES.TUE]: WEEKDAYS,
  [WEEKDAY_NAMES.WED]: WEEKDAYS,
  [WEEKDAY_NAMES.THU]: WEEKDAYS,
  [WEEKDAY_NAMES.FRI]: WEEKDAYS,
  [WEEKDAY_NAMES.SAT]: WEEKENDS,
  [WEEKDAY_NAMES.SUN]: WEEKENDS,
};

export const WEEKDAYS_VALUE_ON_TYPE_MAP = {
  [Object.values(DAY_VALUE_MAP).reduce((acc, value) => acc + value, 0)]:
    EVERYDAY,
  [Object.entries(DAY_VALUE_MAP).reduce(
    (acc, [day, value]) =>
      WEEKDAY_TYPES[day] === WEEKDAYS ? acc + value : acc,
    0
  )]: WEEKDAYS,
  [Object.entries(DAY_VALUE_MAP).reduce(
    (acc, [day, value]) =>
      WEEKDAY_TYPES[day] === WEEKENDS ? acc + value : acc,
    0
  )]: WEEKENDS,
};

export const WEEKDAY_TARGET_DEFAULT = {
  [WEEKDAY_NAMES.MON]: false,
  [WEEKDAY_NAMES.TUE]: false,
  [WEEKDAY_NAMES.WED]: false,
  [WEEKDAY_NAMES.THU]: false,
  [WEEKDAY_NAMES.FRI]: false,
  [WEEKDAY_NAMES.SAT]: false,
  [WEEKDAY_NAMES.SUN]: false,
};

export enum DAILY_STATUS {
  SUCCESS = 'success',
  FAIL = 'fail',
  EMPTY = 'empty',
  PASS = 'pass',
}

export const STATUS_ICONS = {
  [DAILY_STATUS.SUCCESS]: iconNames.checkSmall,
  [DAILY_STATUS.EMPTY]: iconNames.bookmark,
  [DAILY_STATUS.FAIL]: iconNames.crossSmall,
};

export const TARGET_PERIODS = {
  PER_DAY: 'day',
  PER_WEEK: 'week',
  PER_SPRINT: 'sprint',
};

export const EXPLORE_DIALOG_TABS = Object.freeze({
  CURATED: 'curated',
  CUSTOM: 'custom',
});

export const EXPLORE_DIALOG_DEFAULT_DIMENSIONS_FILTER_VALUE = null;

export const DEFAULT_REMINDER_VALUE = '09:00:00';

export const DEFAULT_EMOJI = '🎯';

export const CHALLENGE_LOCATIONS = Object.freeze({
  ACTIVE_SPRINT: 'activeSprint',
  FINISHED_SPRINT: 'finishedSprint',
  BACKLOG: 'backlog',
  DRAFT_SPRINT: 'draftSprint',
});

export const CHALLENGE_DIFFICULTY_RATINGS = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
} as const;

export const CHALLENGE_DIFFICULTY_LABELS = {
  [CHALLENGE_DIFFICULTY_RATINGS.LOW]: 'Low',
  [CHALLENGE_DIFFICULTY_RATINGS.MEDIUM]: 'Medium',
  [CHALLENGE_DIFFICULTY_RATINGS.HIGH]: 'High',
} as const;

export const difficultyRatingOptions = [
  {
    value: CHALLENGE_DIFFICULTY_RATINGS.LOW,
    label: CHALLENGE_DIFFICULTY_LABELS[CHALLENGE_DIFFICULTY_RATINGS.LOW],
  },
  {
    value: CHALLENGE_DIFFICULTY_RATINGS.MEDIUM,
    label: CHALLENGE_DIFFICULTY_LABELS[CHALLENGE_DIFFICULTY_RATINGS.MEDIUM],
  },
  {
    value: CHALLENGE_DIFFICULTY_RATINGS.HIGH,
    label: CHALLENGE_DIFFICULTY_LABELS[CHALLENGE_DIFFICULTY_RATINGS.HIGH],
  },
];

export const DEFAULT_CHALLENGE_DAILY_STATUS = {
  status: DAILY_STATUS_VALUES.EMPTY,
  amount: 0,
  amountTotal: 0,
  amountChangeValue: 0,
  notes: [],
  noteIds: [],
  proofs: [],
  proofDetails: null,
};
