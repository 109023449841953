import { toastErrorConfig } from 'lib/reactToastify/config';
import { Else, If, Then } from 'react-if';
import { toast } from 'react-toastify';

import { TASK_MANAGER_FEATURE_FLAG, useFeatureFlag } from 'featureFlags';
import { Typography } from 'shared';
import { Nullable } from 'shared_DEPRECATED/types';
import { dateUtils } from 'shared_DEPRECATED/utils';

import { SessionDateSelector } from 'features/participants/components/Table';
import { ParticipantsTableRowDataType } from 'features/participants/config/types';
import { useSessionDateMutation } from 'features/participants/hooks';

type SessionDateCellProps = {
  value: {
    appointmentId: string;
    startAt: string;
  };
  row: ParticipantsTableRowDataType;
};
export const SessionDateCell = ({
  value: sessionAppointment,
  row,
}: SessionDateCellProps) => {
  const sessionDateMutation = useSessionDateMutation({
    userEmail: row.original.userInfo.email,
    appointmentId: sessionAppointment?.appointmentId,
  });
  const isTaskMangerFFEnabled = useFeatureFlag(TASK_MANAGER_FEATURE_FLAG);

  const currentDateString = sessionAppointment?.startAt;

  const handleSessionDateMutation = async (
    updatedDateString: Nullable<string>
  ) => {
    try {
      await sessionDateMutation.mutateAsync({
        currentDateString,
        updatedDateString,
      });
    } catch (e) {
      toast.error(
        'Unable to update session date. Try again later.',
        toastErrorConfig
      );
    }
  };

  return (
    <If condition={isTaskMangerFFEnabled}>
      <Then>
        <Typography
          type="small"
          fontWeight={currentDateString ? 'medium' : 'regular'}
          color={currentDateString ? 'black' : 'gray'}
        >
          {currentDateString
            ? dateUtils.localDate(currentDateString).format('MMM D, h:mm A')
            : 'No date and time'}
        </Typography>
      </Then>
      <Else>
        <SessionDateSelector
          sessionDateString={currentDateString}
          onDateChange={handleSessionDateMutation}
          key={currentDateString}
        />
      </Else>
    </If>
  );
};
