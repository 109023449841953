import {
  Box,
  Button,
  type ButtonProps,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
  type UseRadioProps,
} from '@chakra-ui/react';
import type React from 'react';

import { HStack, VARIANTS } from 'shared';

interface RadioButtonProps extends UseRadioProps {
  children: React.ReactNode;
  buttonProps?: ButtonProps;
}
interface RadioButtonOption {
  value: string;
  label: string;
}

interface RadioButtonGroupProps extends UseRadioGroupProps {
  options: RadioButtonOption[];
  buttonProps?: React.ComponentProps<typeof RadioButton>['buttonProps'];
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  options,
  buttonProps,
  ...radioGroupProps
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup(radioGroupProps);

  return (
    <HStack {...getRootProps()}>
      {options.map((option) => (
        <RadioButton
          key={option.value}
          {...getRadioProps({ value: option.value })}
          buttonProps={buttonProps}
        >
          {option.label}
        </RadioButton>
      ))}
    </HStack>
  );
};

export const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const { children, buttonProps, ...radioProps } = props;
  const { getInputProps, getRadioProps } = useRadio(radioProps);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" _focus={{ outline: 'none' }} _active={{ outline: 'none' }}>
      <input {...input} />
      <Button
        as={Box}
        _focus={{ outline: 'none' }}
        _active={{ outline: 'none' }}
        {...checkbox}
        {...buttonProps}
        variant={props.isChecked ? VARIANTS.PRIMARY : VARIANTS.SECONDARY}
        cursor="pointer"
      >
        {children}
      </Button>
    </Box>
  );
};
