import { Nullable } from 'shared_DEPRECATED/types';

type RecapTemplateProps = {
  userName?: string;
  coachName?: string;
  miroLink: string;
  nextSessionDate: Nullable<string>;
};

const HighlightedText = ({ text }: { text: string }) => (
  <span style={{ backgroundColor: 'fgWarning' }}>{text}</span>
);

export const SessionsFormRecapsTodayWeCoveredTemplate = ({
  userName,
  coachName,
  miroLink,
  nextSessionDate,
}: RecapTemplateProps) => (
  <>
    <p>Hi {userName},</p>
    <p>
      It was great meeting with you just now! Sending over a recap from our
      session:
    </p>
    <p>Today we covered:</p>
    <ol>
      {['A', 'B', 'C'].map((item) => (
        <li>
          <HighlightedText text={item} />
        </li>
      ))}
    </ol>
    <p>Here are your immediate next steps:</p>
    <ol>
      {['A', 'B', 'C'].map((item) => (
        <li>
          <HighlightedText text={item} />
        </li>
      ))}
    </ol>
    <p>
      Here’s a resource [HYPERLINK] that I think would be helpful based on our
      convo. Check it out and let me know what you think!
    </p>
    <p>
      If you have any questions, let me know. Looking forward to our next
      session on{' '}
      {nextSessionDate ? (
        <i>
          <b>{nextSessionDate}</b>
        </i>
      ) : (
        <HighlightedText text="[INSERT NEXT SESSION DAY + TIME]" />
      )}
    </p>
    <br />
    <p>Talk soon,</p>
    <p>{coachName}</p>
  </>
);
