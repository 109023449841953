export const COACH_SEARCH_ENABLED = 'coachSearchEnabled';
export const SPRINT_DURATION = 'sprintDuration';
export const PLANNING_FEATURE_FLAG = 'sprintPlanning';
export const COACH_INSIGHTS_FEATURE_FLAG = 'coachInsights';
export const GOALS_DND_FEATURE_FLAG = 'webGoalsDnd';
export const SESSION_RECAP_PREVIEW_FEATURE_FLAG = 'webSessionRecapPreview';
export const IMPROVED_GROUP_VIEW_FEATURE_FLAG = 'improvedGroupView';
export const GOAL_DEEP_DIVE_MARKDOWN_FEATURE_FLAG = 'goalDeepDiveMarkdown';
export const JOURNAL_FEATURE_FLAG = 'webJournal';
export const MULTIPLE_PROFILES_FEATURE_FLAG = 'multipleProfiles';
export const TASK_MANAGER_FEATURE_FLAG = 'taskManager';
export const TIMELINE_FEATURE_FLAG = 'infiniteTimeline';
export const WEIGHTED_CHALLENGES_FEATURE_FLAG = 'weightedChallenges';
export const PLANNING_START_SPRINT_BUTTON_FEATURE_FLAG =
  'webPlanningStartSprint';
