import { useCallback } from 'react';

import { SprintHistory } from 'features/sprint/components/History';

import { sidebar } from 'shared/components/Sidebar';

import { useSprintAnalytics } from './useSprintAnalytics';

export const useHistorySidebar = ({ sprintId, sprints }) => {
  const { sendHistorySidebarOpenedEvent } = useSprintAnalytics();

  const openHistorySidebar = useCallback(() => {
    sidebar.open({
      content: <SprintHistory />,
      props: { title: 'Sprint History Log' },
      context: {
        currentSprintId: sprintId,
        sprints,
      },
    });
    sendHistorySidebarOpenedEvent();
  }, [sprints, sprintId, sendHistorySidebarOpenedEvent]);

  return { openHistorySidebar };
};
