import { Nullable } from 'shared_DEPRECATED/types';

type RecapTemplateProps = {
  userName?: string;
  coachName?: string;
  miroLink: string;
  nextSessionDate: Nullable<string>;
};

const HighlightedText = ({ text }: { text: string }) => (
  <span style={{ backgroundColor: 'fgWarning' }}>{text}</span>
);

export const SessionFormRecapImmediateNextStepsTemplate = ({
  userName,
  coachName,
  miroLink,
  nextSessionDate,
}: RecapTemplateProps) => (
  <>
    <p>Hi {userName},</p>
    <p>
      Excited to kick things into gear following that session, let’s get right
      to it. Here are your immediate next steps:
    </p>
    <ol>
      {['A', 'B', 'C'].map((item) => (
        <li>
          <HighlightedText text={item} />
        </li>
      ))}
    </ol>
    <p>
      If you’ve got any questions as you’re implementing, send me a chat
      message.
    </p>
    <p>
      Otherwise, I’ll see you on our next call on{' '}
      {nextSessionDate ? (
        <i>
          <b>{nextSessionDate}</b>
        </i>
      ) : (
        <HighlightedText text="[INSERT NEXT SESSION DAY + TIME]" />
      )}
    </p>
    <br />
    <p>Talk soon,</p>
    <p>{coachName}</p>
  </>
);
