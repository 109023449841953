import { SprintSourceType } from 'features/sprint/config/types';

import { useSprintStartSubmit } from './useSprintStartSubmit';

const useSprintStart = ({
  sprintId,
  sprintType,
  sprintChallengesCount,
  sprintTitle,
}: {
  sprintId: string;
  sprintType: SprintSourceType;
  sprintChallengesCount: number;
  sprintTitle: string;
}) => {
  const onStartSprintButtonClick = useSprintStartSubmit({
    sprintId,
    sprintType,
  });

  const isSprintReadyToStart = sprintChallengesCount > 0 && sprintTitle;

  return {
    isSprintReadyToStart,
    onStartSprintButtonClick,
  };
};

export default useSprintStart;
