import PropTypes from 'prop-types';
import { Fragment } from 'react';

import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { calendarSections } from 'features/sprint/config';

import { Typography } from 'shared/components/Typography';

const SprintHistorySectionPropTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.oneOf(Object.values(calendarSections)),
};

const SprintHistorySection = ({ children, title, index }) => {
  const Wrapper = index === 0 ? Fragment : Spacer;
  const wrapperProps = index === 0 ? {} : { size: 'lg zr zr' };

  return (
    <Box as="li" style={{ width: '100%' }}>
      <Wrapper {...wrapperProps}>
        <Spacer size="zr lg">
          <Typography as="h4" color="gray" fontWeight="semiBold" type="small">
            {title}
          </Typography>
        </Spacer>
        <Flex as="ul" flexDirection="column" alignItems="stretch">
          {children}
        </Flex>
      </Wrapper>
    </Box>
  );
};

SprintHistorySection.propTypes = SprintHistorySectionPropTypes;

export default SprintHistorySection;
