import { dateUtils } from 'shared_DEPRECATED/utils';

import { MomentumType } from 'features/momentum/config';

import { SPRINT_SOURCE_TYPES, SPRINT_STATES } from './config';
import {
  IFinalSprint,
  SprintSourceType,
  IFinalSprintProps,
} from './config/types';

const getSprintWeeksAsFormattedDates = (
  startDateStr: string,
  endDateStr: string
) => {
  if (!startDateStr || !endDateStr) {
    return [];
  }

  const startDate = dateUtils(startDateStr);
  const endDate = dateUtils(endDateStr);

  const sprintWeeksDateRange = [];
  let currentDay = startDate;

  do {
    const week = [];

    for (let i = 0; i < dateUtils.WEEK_DAYS_COUNT; i++) {
      week.push(currentDay.format('YYYY-MM-DD'));
      currentDay = currentDay.add(1, 'day');
    }

    sprintWeeksDateRange.push(week);
  } while (currentDay.isBefore(endDate.toDate()));

  return sprintWeeksDateRange;
};

//TODO  https://hosinc.atlassian.net/browse/HOS-7774
export class FinalSprint implements IFinalSprint {
  readonly currentWeekNumber: number;
  readonly endDate: string;
  readonly source?: { qssId: string };
  readonly sprintId: string;
  readonly sprintType: SprintSourceType;
  readonly state: string;
  readonly startDate: string;
  title: string;
  readonly weekNumber?: number;
  readonly momentumScores: MomentumType;
  readonly updatedAt: string;
  constructor(sprint: IFinalSprintProps) {
    this.endDate = dateUtils.mapDateToSunday(sprint.endDate);
    this.source = sprint.source;
    this.sprintId = sprint.sprintId;
    this.sprintType = sprint.sprintType || SPRINT_SOURCE_TYPES.TRADITIONAL;
    this.startDate = dateUtils.mapDateToMonday(sprint.startDate);
    this.state = sprint.state || SPRINT_STATES.DRAFT;
    this.title = sprint.title;
    this.momentumScores = sprint.momentumScores || [];
    this.currentWeekNumber =
      sprint.currentWeekNumber ||
      sprint.weekNumber ||
      this.calculateCurrentWeekNumber();
    this.updatedAt = sprint.updatedAt || '';
  }

  get formattedDate() {
    const formattedStartDate = dateUtils(this.startDate).format('MMM D');
    const formattedEndDate = dateUtils(this.endDate).format('MMM D');
    const startDateYear = dateUtils(this.startDate).year();
    const endDateYear = dateUtils(this.endDate).year();

    return `${formattedStartDate}${
      startDateYear !== endDateYear ? ', ' + startDateYear : ''
    } - ${formattedEndDate}, ${endDateYear}`;
  }

  get isActive(): boolean {
    return this.state === SPRINT_STATES.ACTIVE;
  }

  get isFinished(): boolean {
    return this.state === SPRINT_STATES.FINISHED;
  }

  get sprintTypeLabel(): string {
    let label = '';

    switch (this.sprintType) {
      case SPRINT_SOURCE_TYPES.TRADITIONAL:
        label = 'Traditional';
        break;
      case SPRINT_SOURCE_TYPES.QSS:
        label = 'QSS';
        break;
      case SPRINT_SOURCE_TYPES.QSS_EDITED:
        label = 'QSS • Edited';
        break;
      default:
        label = 'No sprint type';
    }

    return label;
  }

  get weeks() {
    return getSprintWeeksAsFormattedDates(this.startDate, this.endDate);
  }

  get weeksCount(): number {
    const weeks = this.weeks;

    return weeks.length;
  }

  private calculateCurrentWeekNumber(): number {
    const currentDateTimestamp = dateUtils().startDayDateObject().getTime();

    let currentWeekNumber = dateUtils.isCurrentDateBeforeDate(this.startDate)
      ? 0
      : this.weeks.findIndex(([weekStart, , , , , , weekEnd]) => {
          return (
            currentDateTimestamp >=
              dateUtils.timestampFromFormattedDate(weekStart) &&
            currentDateTimestamp <=
              dateUtils.timestampFromFormattedDate(weekEnd)
          );
        });

    if (
      !dateUtils.isCurrentDateBeforeDate(this.startDate) &&
      (this.state === SPRINT_STATES.FINISHED || currentWeekNumber === -1)
    ) {
      currentWeekNumber = this.weeksCount;
    }

    return currentWeekNumber;
  }
}
