import { When } from 'react-if';

import { useIsCoachMode } from '../hooks/useIsCoachMode';

type RenderIfCoachProps = {
  children: React.ReactNode;
};

export const RenderIfCoach = ({ children }: RenderIfCoachProps) => {
  const isCoachMode = useIsCoachMode();

  return <When condition={isCoachMode}>{children}</When>;
};
