import Loader from 'shared_DEPRECATED/components/Loader';

type LoadingWrapperProps = {
  children: React.ReactNode;
  isLoading: boolean;
};

export const LoadingWrapper = ({
  children,
  isLoading,
}: LoadingWrapperProps) => <>{isLoading ? <Loader /> : children}</>;
