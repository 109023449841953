import { NonRepeatedChallenge } from 'features/challenge/NonRepeatedChallenge';
import { RepeatedChallenge } from 'features/challenge/RepeatedChallenge';
import {
  CHALLENGE_FIELDS,
  MAX_SPRINT_CHALLENGES_COUNT,
} from 'features/challenge/config';
import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import { ICoachingTool } from 'features/coachingTool';
import { CoachingTool } from 'features/coachingTool/CoachingTool';
import {
  IPlanningDraftSprint,
  IPlanningDraftSprintProps,
} from 'features/planning';
import { SPRINT_SOURCE_TYPES } from 'features/sprint/config';
import { SprintSourceType } from 'features/sprint/config/types';

export class PlanningDraftSprint implements IPlanningDraftSprint {
  challenges: (IRepeatedChallenge | INonRepeatedChallenge)[];
  weeksCount?: number;
  readonly source?: { qssId: string };
  readonly sprintType: SprintSourceType;
  title: string;
  readonly userEmail?: string;
  readonly userName?: string;
  readonly userPicture?: any;
  readonly updatedAt: string;
  readonly sprintId: string;
  coachingTools: ICoachingTool[];

  constructor(sprint: IPlanningDraftSprintProps) {
    this.challenges = sprint.challenges.map((challenge) =>
      this.isNonRepeatedChallenge(challenge)
        ? new NonRepeatedChallenge({
            ...challenge,
            sprintId: sprint.sprintId,
          })
        : new RepeatedChallenge({
            ...challenge,
            sprintId: sprint.sprintId,
          })
    );
    this.source = sprint.source;
    this.sprintType = sprint.sprintType;
    this.title = sprint.title;
    this.userEmail = sprint.userEmail;
    this.userName = sprint.userName;
    this.userPicture = sprint.userPicture;
    this.weeksCount = sprint.weeksCount;
    this.updatedAt = sprint.updatedAt;
    this.sprintId = sprint.sprintId;
    this.coachingTools = sprint.coachingTools.map(
      (coachingTool) => new CoachingTool(coachingTool)
    );
  }

  get availableChallengeFields(): Record<
    typeof CHALLENGE_FIELDS[keyof typeof CHALLENGE_FIELDS],
    boolean
  > {
    const { challenges, oneTimeChallenges } = this.getChallengesByType();

    const isChallengesWithinMaxCount = (
      challenges: (INonRepeatedChallenge | IRepeatedChallenge)[]
    ) => challenges.length <= MAX_SPRINT_CHALLENGES_COUNT;

    return {
      [CHALLENGE_FIELDS.CHALLENGES]: isChallengesWithinMaxCount(challenges),
      [CHALLENGE_FIELDS.ONE_TIME_CHALLENGES]:
        isChallengesWithinMaxCount(oneTimeChallenges),
    };
  }

  isNonRepeatedChallenge(
    challenge: INonRepeatedChallenge | IRepeatedChallenge
  ): challenge is INonRepeatedChallenge {
    return !!(challenge as INonRepeatedChallenge).target?.dueDate;
  }

  get challengesCount() {
    return this.challenges.length;
  }

  get challengesCountLabel() {
    const challengesCount = this.challengesCount;

    return `${challengesCount} ${
      challengesCount === 1 ? 'challenge' : 'challenges'
    }`;
  }

  get formattedDate() {
    return null;
  }

  get hasChallenges(): boolean {
    return this.challengesCount > 0;
  }

  get isReadyToStart() {
    return Boolean(this.challengesCount > 0 && this.title);
  }

  get sprintSource(): { qssId: string } | undefined {
    return this.source;
  }

  get sprintTypeLabel(): string {
    let label = '';

    switch (this.sprintType) {
      case SPRINT_SOURCE_TYPES.TRADITIONAL:
        label = 'Traditional';
        break;
      case SPRINT_SOURCE_TYPES.QSS:
        label = 'QSS';
        break;
      case SPRINT_SOURCE_TYPES.QSS_EDITED:
        label = 'QSS • Edited';
        break;
      default:
        label = 'No sprint type';
    }

    return label;
  }

  get state(): 'draft' {
    return 'draft';
  }

  get titleWithStateLabel() {
    return 'Bridge Mode';
  }

  getChallengesByType() {
    return this.challenges.reduce(
      (acc, challenge) => {
        if (challenge instanceof RepeatedChallenge) {
          acc[CHALLENGE_FIELDS.CHALLENGES].push(challenge);
        } else if (challenge instanceof NonRepeatedChallenge) {
          acc[CHALLENGE_FIELDS.ONE_TIME_CHALLENGES].push(challenge);
        }
        return acc;
      },
      {
        [CHALLENGE_FIELDS.CHALLENGES]: [] as IRepeatedChallenge[],
        [CHALLENGE_FIELDS.ONE_TIME_CHALLENGES]: [] as INonRepeatedChallenge[],
      }
    );
  }
}
