import {
  notificationProps,
  notificationStatus,
} from 'app/notifications/config';
import { useNotificationCallback } from 'app/notifications/hooks';
import {
  getNotificationText,
  getNotificationUserInfo,
} from 'app/notifications/utils';
import classNames from 'classnames/bind';
import { Avatar, SIZES } from 'shared_DEPRECATED/components/Avatar';
import Button from 'shared_DEPRECATED/components/Button';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { dateUtils, getPictureUrl } from 'shared_DEPRECATED/utils';

import { Typography } from 'shared/components/Typography';

import styles from 'app/notifications/components/Notifications.module.css';

const cx = classNames.bind(styles);

const notificationItemProps = {
  notification: notificationProps,
};

export const NotificationsItem = ({ notification }) => {
  const { editor, action, title } = getNotificationText(notification);
  const { name, email, picture } = getNotificationUserInfo(notification);

  const handleNotificationClick = useNotificationCallback(notification);

  const imageClassNames = cx({
    'notification__user-avatar': true,
    'notification__user-avatar--active':
      notification.status === notificationStatus.unread,
  });
  const wrapperClassNames = cx({
    notification__item: true,
    'notification__item--active':
      notification.status === notificationStatus.unread,
  });

  return (
    <Button onClick={handleNotificationClick} className={wrapperClassNames}>
      <Spacer size="lg">
        <Spacer size="sm zr">
          <Flex justifyContent="initial" alignItems="flex-start">
            <div className={imageClassNames}>
              <Avatar
                name={name || email}
                size={SIZES.MD}
                src={getPictureUrl(picture)}
              />
            </div>
            <Spacer size="zr zr zr md">
              <Spacer size="zr zr md zr">
                <Typography
                  type="small"
                  as="span"
                  color="black"
                  fontWeight="semiBold"
                >
                  {editor}
                </Typography>
                <Spacer as="span" size="zr sm">
                  <Typography
                    type="small"
                    as="span"
                    color="black"
                    fontWeight="regular"
                  >
                    {action}
                  </Typography>
                </Spacer>
                {title && (
                  <Typography
                    type="small"
                    as="span"
                    color="black"
                    fontWeight="semiBold"
                  >
                    {title}
                  </Typography>
                )}
              </Spacer>
              <Typography type="small">
                {dateUtils(
                  dateUtils.localDate(notification.createdAt)
                ).timeAgoDate()}
              </Typography>
            </Spacer>
          </Flex>
        </Spacer>
      </Spacer>
    </Button>
  );
};

NotificationsItem.propTypes = notificationItemProps;
