import { AbsoluteCenter, VStack } from '@chakra-ui/react';

import { Typography } from 'shared';

export const GenericError = () => {
  return (
    <AbsoluteCenter>
      <VStack>
        <Typography as="div" type="largeHeading" textAlign="center">
          🙈
        </Typography>
        <Typography
          as="h1"
          type="heading"
          fontWeight="semiBold"
          textAlign="center"
          color="black"
        >
          Something went wrong
        </Typography>
        <Typography as="p" color="gray" type="meta" textAlign="center">
          Try refreshing the page or come back later.
        </Typography>
      </VStack>
    </AbsoluteCenter>
  );
};
