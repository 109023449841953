import { useNavigate } from 'react-router-dom';

import { TIMELINE_FEATURE_FLAG } from 'featureFlags/config';
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag';
import { ObjectKeys } from 'types/common';

import { USER_TYPE_REDIRECTS } from 'features/user/config';

export const useUserTypeDefaultNavigate = () => {
  const navigate = useNavigate();
  const isTimelineEnabled = useFeatureFlag(TIMELINE_FEATURE_FLAG);

  return (userType: ObjectKeys<typeof USER_TYPE_REDIRECTS>) =>
    navigate(`${USER_TYPE_REDIRECTS[userType](isTimelineEnabled)}`);
};
