import Box from 'shared_DEPRECATED/components/Box';

import { Typography } from 'shared/components/Typography';

type TChallengeInfoNameProps = {
  name: string;
  isCropped?: boolean;
};

export const ChallengeInfoName = ({
  name,
  isCropped = true,
}: TChallengeInfoNameProps) => (
  <Box
    style={{
      maxWidth: '18rem',
    }}
  >
    <Typography
      as="p"
      color="black"
      fontWeight="regular"
      type="meta"
      cropped={isCropped}
      wordBreak={!isCropped ? 'break-word' : 'normal'}
      data-testid="challenge-title"
      title={name}
    >
      {name}
    </Typography>
  </Box>
);
