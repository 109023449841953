import { toastErrorConfig } from 'lib/reactToastify/config';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, ButtonIcon, VARIANTS } from 'shared';
import Box from 'shared_DEPRECATED/components/Box/Box';
import { Flex, FlexItem } from 'shared_DEPRECATED/components/Flex';
import { FormSaveIndicator } from 'shared_DEPRECATED/components/Form/SaveIndicator';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { useFormAutosave } from 'shared_DEPRECATED/hooks';

import CreateChallengeButton from 'features/challenge/components/CreateChallengeButton';
import {
  CHALLENGE_TYPE_FIELDS,
  MAX_SPRINT_CHALLENGES_COUNT,
} from 'features/challenge/config';
import { useOpenChallengeModal } from 'features/challenge/hooks';
import { useExploreChallengesDialog } from 'features/challenge/hooks/useExploreChallengesDialog';
import { useInitialChallenge } from 'features/challenge/hooks/useInitialChallenge';
import { SprintType } from 'features/sprint/utils';

import { Typography } from 'shared/components/Typography';

const SprintFormHeaderPropTypes = {
  sprintPath: PropTypes.string.isRequired,
  sprint: SprintType,
  onSubmit: PropTypes.func.isRequired,
};

const SprintFormHeaderAutoSave = ({ sprintPath, sprint, onSubmit }) => {
  const navigate = useNavigate();
  const sprintFormMethods = useFormContext();
  const openChallengeModal = useOpenChallengeModal();
  const initialChallenge = useInitialChallenge();
  const onExploreBtnClick = useExploreChallengesDialog({
    sprint,
    sprintFormMethods,
  });
  const location = useLocation();

  const {
    formState: { isSubmitting, isDirty, errors },
  } = sprintFormMethods;

  const onCreateChallengeBtnClick = () =>
    openChallengeModal({
      challenge: initialChallenge,
      onChallengeSubmit: (challenge) => {
        const challengeField = CHALLENGE_TYPE_FIELDS[challenge.type];

        sprintFormMethods[challengeField].append(challenge);
      },
      sprint,
      title: 'Create Challenge',
    });

  const validateSprintChallengesCount = (onAddChallengeBtnClick) => {
    const challenges = sprintFormMethods.getValues('challenges');
    const oneTimeChallenges = sprintFormMethods.getValues('oneTimeChallenges');

    if (
      challenges.length === MAX_SPRINT_CHALLENGES_COUNT &&
      oneTimeChallenges.length === MAX_SPRINT_CHALLENGES_COUNT
    ) {
      toast.error(
        'The sprint is full. Maximum 100 challenges per scope.',
        toastErrorConfig
      );
      return;
    }

    onAddChallengeBtnClick();
  };

  const onAbort = () => {
    navigate(location.key === 'default' ? sprintPath : navigate(-1));
  };

  useFormAutosave({
    formMethods: sprintFormMethods,
    onMutate: onSubmit,
    storageKey: `sprint-${sprint.sprintId}`,
  });

  return (
    <>
      <Spacer size="xxlg zr lg">
        <Spacer size="zr zr lg">
          <Flex as="header" justifyContent="space-between" width="100%">
            <Box
              style={{
                position: 'absolute',
                left: 'var(--spacing07)',
                top: 'var(--spacing07)',
              }}
            >
              <ButtonIcon
                icon={<Icon name={iconNames.arrowBigLeft} />}
                onClick={onAbort}
                type="reset"
                dataTestid="arrow-back"
              />
            </Box>
            <Typography
              as="h1"
              color="black"
              fontWeight="semiBold"
              type="heading"
            >
              Edit sprint
            </Typography>
            <FlexItem>
              <CreateChallengeButton
                onCreateChallengeBtnClick={() =>
                  validateSprintChallengesCount(onCreateChallengeBtnClick)
                }
              />
              <Spacer as="span" size="zr md zr zr" />
              <Button
                leftIcon={
                  <Icon
                    name={iconNames.search}
                    stroke="var(--fgPrimary)"
                    width={12}
                    height={12}
                  />
                }
                onClick={() => validateSprintChallengesCount(onExploreBtnClick)}
                variant={VARIANTS.SECONDARY}
                size="sm"
              >
                Explore Challenges
              </Button>
            </FlexItem>
            <Box
              style={{
                position: 'absolute',
                right: 'var(--spacing07)',
                top: 'var(--spacing07)',
              }}
            >
              <FormSaveIndicator
                isDirty={isDirty}
                isSubmitting={isSubmitting}
                savedAt={sprintFormMethods.getValues('updatedAt')}
                error={!!errors?.root?.saveError}
              />
            </Box>
          </Flex>
        </Spacer>
      </Spacer>
    </>
  );
};

SprintFormHeaderAutoSave.propTypes = SprintFormHeaderPropTypes;

export default SprintFormHeaderAutoSave;
