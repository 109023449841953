import { useColorMode } from '@chakra-ui/react';

import { HeaderEnvBanner } from 'app/layout/components/Header';
import Breadcrumbs from 'app/layout/components/Header/Breadcrumbs/Breadcrumbs';
import { MainHeaderTabs } from 'app/layout/components/Header/Tabs';
import Navigation from 'app/layout/components/Navigation';
import { RenderIfFFEnabled, TASK_MANAGER_FEATURE_FLAG } from 'featureFlags';
import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Logo from 'shared_DEPRECATED/components/Logo';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';

import { RenderIfCoach } from 'features/user/components/RenderIfCoach';

import { Typography } from 'shared/components/Typography';

const MainHeader = () => {
  const { colorMode: theme } = useColorMode();
  const isThemeLight = theme === 'light';

  return (
    <>
      <Box
        style={{
          zIndex: 2,
          borderBottom: 'var(--border-secondary)',
        }}
      >
        <header>
          <Spacers sizes={['md lg', 'zr sm']}>
            <Typography as="div" color="gray" type="small" fontWeight="regular">
              <Flex>
                <Flex>
                  <Box
                    style={{
                      width: '2rem',
                      height: '2rem',
                    }}
                  >
                    <Logo src={isThemeLight ? '/hOS.svg' : '/hOS-black.svg'} />
                  </Box>
                  <Spacer size="zr md" />
                  <RenderIfFFEnabled featureFlag={TASK_MANAGER_FEATURE_FLAG}>
                    <RenderIfCoach>
                      <MainHeaderTabs />
                      <Spacer size="zr md" />
                    </RenderIfCoach>
                  </RenderIfFFEnabled>
                  <Breadcrumbs />
                </Flex>
                <Navigation />
              </Flex>
            </Typography>
          </Spacers>
        </header>
      </Box>
      <HeaderEnvBanner />
    </>
  );
};

export default MainHeader;
