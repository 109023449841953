import { useLocation } from 'react-router-dom';

import { NavRadioGroup } from 'shared';

type MainHeaderTabsProps = {};

const options = [
  { value: '/coach/participants', label: 'Participants' },
  { value: '/coach/task-manager', label: 'Task Manager' },
];

export const MainHeaderTabs = (props: MainHeaderTabsProps) => {
  const location = useLocation();
  // /coach/participants or /coach/task-manager
  const [, first, second] = location.pathname.split('/');
  const subRoute = `/${first}/${second}`;

  return (
    <NavRadioGroup
      options={options}
      value={subRoute}
      buttonProps={{ size: 'xs' }}
    />
  );
};
