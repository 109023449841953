import { toastErrorConfig } from 'lib/reactToastify/config';
import PropTypes from 'prop-types';
import { lazy, Suspense } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useSprintDurationFeatureFlag } from 'featureFlags/hooks/useSprintDurationFeatureFlag';
import { Button, ButtonIcon, VARIANTS } from 'shared';
import Box from 'shared_DEPRECATED/components/Box';
import CriticalDialogContent from 'shared_DEPRECATED/components/Dialog';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { Icon, iconNames } from 'shared_DEPRECATED/components/Icon';
import Spacer, { Spacers } from 'shared_DEPRECATED/components/Spacer';
import { dateUtils, getPictureUrl } from 'shared_DEPRECATED/utils';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { DueDateField } from 'features/challenge/components/Form/fields';
import { ChallengeTargetField } from 'features/challenge/components/Form/fields/TargetField';
import { ChallengeInfo } from 'features/challenge/components/Info';
import { ChallengeInfoGoals } from 'features/challenge/components/Info/Goals';
import { ChallengeTargetAmountDialogContent } from 'features/challenge/components/TargetAmount/Dialog/Content';
import {
  CHALLENGE_TYPE_FIELDS,
  CHALLENGE_TYPES,
  TARGET_TYPES,
  MIN_SPRINT_CHALLENGES_COUNT,
} from 'features/challenge/config';
import { useOpenChallengeModal } from 'features/challenge/hooks';
import { useGetChallengeFrequencyText } from 'features/challenge/hooks/useGetFrequencyText';
import { useIsChallengeFrequencyConfigurable } from 'features/challenge/hooks/useIsFrequencyConfigurable';
import { useSprintFormData } from 'features/sprint/hooks/useSprintFormData';
import { ChallengeType, isDraftSprint } from 'features/sprint/utils';

import { modal, SIZES } from 'shared/components/Modal';
import { Typography } from 'shared/components/Typography';

const FrequencyDialogContent = lazy(() =>
  lazyRetry(
    () =>
      import(
        'features/challenge/components/FrequencyDialog/FrequencyDialogContent'
      ),
    'FrequencyDialog'
  )
);

const SprintFormChallengeItemPropTypes = {
  challenge: ChallengeType.isRequired,
  onChallengeUpdate: PropTypes.func.isRequired,
  onChallengeRemove: PropTypes.func.isRequired,
};

export const SprintFormChallengeItem = ({
  challenge,
  onChallengeUpdate,
  onChallengeRemove,
}) => {
  const getChallengeFrequencyText = useGetChallengeFrequencyText();
  const formMethods = useFormContext();
  const sprint = useSprintFormData(formMethods);
  const isSprintDraft = isDraftSprint(sprint.state);
  const challengesCount = sprint.challengesCount;
  const openChallengeModal = useOpenChallengeModal();
  const sprintWeeksCount = useSprintDurationFeatureFlag();
  const isChallengeFrequencyConfigurable = useIsChallengeFrequencyConfigurable({
    challengeTargetPeriod: challenge.target.period,
  });

  const onChallengeDelete = () => {
    if (!isSprintDraft && challengesCount === MIN_SPRINT_CHALLENGES_COUNT) {
      toast.error('At least one challenge is required.', toastErrorConfig);

      return;
    }

    modal.open({
      content: (
        <CriticalDialogContent
          title="Remove challenge from sprint?"
          subTitle="All the current challenge progress will be lost."
          submitTitle="Remove"
          onSubmit={onChallengeRemove}
        />
      ),
      props: {
        size: SIZES.SM,
        title: 'Remove challenge from sprint',
      },
      context: { sprint },
    });
  };

  const onEditBtnClick = () => {
    openChallengeModal({
      challenge,
      onChallengeSubmit: (updatedChallenge) => {
        if (updatedChallenge.type === challenge.type) {
          onChallengeUpdate(updatedChallenge);

          return;
        }

        const nextChallengeField = CHALLENGE_TYPE_FIELDS[updatedChallenge.type];
        const { append } = formMethods[nextChallengeField];

        append(updatedChallenge);
        onChallengeRemove();
      },
      sprint,
    });
  };

  const handleTargetAmountChange = ({ period, ...targetAmount }) => {
    onChallengeUpdate({
      ...challenge,
      target: {
        ...challenge.target,
        type: isChallengeFrequencyConfigurable
          ? challenge.target.type
          : TARGET_TYPES.WEEKLY,
        weekday: isChallengeFrequencyConfigurable
          ? challenge.target.weekday
          : undefined,
        weekly: isChallengeFrequencyConfigurable
          ? challenge.target.weekly
          : new Array(sprintWeeksCount).fill(dateUtils.WEEK_DAYS_COUNT),
        amount: {
          ...challenge.target.amount,
          ...targetAmount,
        },
        period,
      },
    });
  };

  const onTargetAmountChange = () => {
    modal.open({
      content: (
        <ChallengeTargetAmountDialogContent
          onSave={handleTargetAmountChange}
          initialTargetAmountValue={challenge.target.amount?.value}
          initialTargetAmountUnit={challenge.target.amount?.unit}
          initialTargetPeriod={challenge.target.period}
        />
      ),
      props: { size: SIZES.LG },
    });
  };

  const handleFrequencyChange = (target) => {
    onChallengeUpdate({
      ...challenge,
      target: {
        ...challenge.target,
        ...target,
      },
    });
  };

  const onFrequencyChange = () => {
    modal.open({
      content: (
        <Suspense fallback={null}>
          <FrequencyDialogContent
            onFrequencySave={handleFrequencyChange}
            challengeTarget={challenge.target}
            sprintWeeksCount={sprint.weeksCount}
          />
        </Suspense>
      ),
      props: {
        size: SIZES.MD,
        title: 'Change frequency',
      },
      context: { currentWeekNumber: sprint.currentWeekNumber },
    });
  };

  const onDueDateChange = (date) => {
    onChallengeUpdate({
      ...challenge,
      target: {
        ...challenge.target,
        dueDate: dateUtils(date).format('YYYY-MM-DD'),
      },
    });
  };

  return (
    <Box data-testid={`challenge-${challenge.sprintChallengeId}`}>
      <Spacer size="md sm">
        <Flex justifyContent="space-between">
          <Spacers sizes={['zr lg zr zr', 'zr sm zr zr']}>
            <ChallengeInfo
              emoji={challenge.emoji}
              pictureUrl={getPictureUrl(challenge.picture)}
              pictureFileUrl={
                challenge.pictureFile instanceof File
                  ? URL.createObjectURL(challenge.pictureFile)
                  : ''
              }
              title={challenge.title}
              isPrivate={challenge.private}
            >
              <ChallengeInfoGoals goals={challenge.goals} />
              <Spacer size="xsm" />
              <Typography as="p" color="gray" type="small" fontWeight="regular">
                {challenge.dimensions.join(', ')}
              </Typography>
            </ChallengeInfo>
          </Spacers>
          <Flex>
            {challenge.type === CHALLENGE_TYPES.REPEATING && (
              <ChallengeTargetField
                challengeTargetAmount={challenge.target.amount}
                challengeTargetPeriod={challenge.target.period}
                onFieldClick={onTargetAmountChange}
                onDeleteButtonClick={() => {
                  onChallengeUpdate({
                    ...challenge,
                    target: {
                      ...challenge.target,
                      amount: null,
                      period: null,
                    },
                  });
                }}
              />
            )}
            {challenge.type === CHALLENGE_TYPES.REPEATING ? (
              isChallengeFrequencyConfigurable && (
                <Spacer size="zr zr zr lg">
                  <Button
                    leftIcon={
                      <Icon name={iconNames.calendar} width={12} height={14} />
                    }
                    onClick={onFrequencyChange}
                    variant={VARIANTS.TERTIARY}
                    size="sm"
                  >
                    {getChallengeFrequencyText(
                      challenge.target,
                      sprint.weeksCount
                    )}
                  </Button>
                </Spacer>
              )
            ) : (
              <Spacer size="zr zr zr lg">
                <DueDateField
                  dueDate={challenge.target.dueDate}
                  onDateChange={onDueDateChange}
                  startDate={sprint.startDate}
                />
              </Spacer>
            )}
            <Spacer size="zr sm zr xlg">
              <ButtonIcon
                dataTestid="edit-challenge-btn"
                onClick={onEditBtnClick}
                variant={VARIANTS.TERTIARY}
                icon={<Icon name={iconNames.edit} width={12} height={12} />}
                size="sm"
              />
            </Spacer>
            <ButtonIcon
              onClick={onChallengeDelete}
              dataTestid="delete-challenge-btn"
              icon={<Icon name={iconNames.bin} width={12} height={12} />}
              size="sm"
            />
          </Flex>
        </Flex>
      </Spacer>
    </Box>
  );
};

SprintFormChallengeItem.propTypes = SprintFormChallengeItemPropTypes;
