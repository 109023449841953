import { Nullable } from 'shared_DEPRECATED/types';

type RecapTemplateProps = {
  userName?: string;
  coachName?: string;
  miroLink: string;
  nextSessionDate: Nullable<string>;
};

const HighlightedText = ({ text }: { text: string }) => (
  <span style={{ backgroundColor: 'fgWarning' }}>{text}</span>
);

const MiroLink = ({ link }: { link: string }) => (
  <a href={link} target="_blank" rel="noreferrer">
    {link}
  </a>
);

export const SessionsFormRecapStandardTemplate = ({
  userName,
  coachName,
  miroLink,
  nextSessionDate,
}: RecapTemplateProps) => (
  <>
    <p>Hi {userName},</p>
    <p>
      It was great meeting with you today! Here is a quick reminder of your
      takeaways and action items from our conversation:
    </p>
    <ol>
      {['A', 'B', 'C'].map((item) => (
        <li>
          <HighlightedText text={item} />
        </li>
      ))}
    </ol>
    <p>
      For your reference, here is the miro board we worked on during our
      session: {miroLink?.length && <MiroLink link={miroLink} />}
    </p>
    {miroLink?.length === 0 && (
      <p>
        <HighlightedText text="[LINK PREPOPULATES FROM TOOLS SECTION]" />
      </p>
    )}
    <p>
      <HighlightedText
        text="[Optional: INSERT VALUE ADD: Note from session, reflection for
        participant or share a resource if relevant. Do not force this. Seek to
        surprise and wow]."
      />
    </p>
    <br />
    <p>
      As you’re working through things, don’t hesitate to reach out if you have
      any questions, stuck points or celebrations to share. I always love
      hearing from you!
    </p>
    <p>
      Looking forward to our next session on{' '}
      {nextSessionDate ? (
        <i>
          <b>{nextSessionDate}</b>
        </i>
      ) : (
        <HighlightedText text="[INSERT NEXT SESSION DAY + TIME]" />
      )}
    </p>
    <br />
    <p>Talk soon,</p>
    <p>{coachName}</p>
  </>
);
